import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_theme/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_theme/layout/core'
import {MasterInit} from '../_theme/layout/MasterInit'
import {AuthInit} from './modules/auth'

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../utils/appInsights';

const App = () => {
  return (
    <div className="d-flex flex-column min-vh-100">
      <Suspense fallback={<LayoutSplashScreen />}>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
      </Suspense>
    </div>
  )
}

export default withAITracking(reactPlugin, App)
