import { Formik } from "formik";
import { CloseModal } from "../../../../_theme/helpers/components/modalHelpers";
import { ModalWrapper } from "../../../components/shared/ModalWrapper";
import { IInputMovement } from "../interfaces/IInputMovement";
import { useState } from "react";
import toast from "react-hot-toast";
import { del } from "../../../../utils/ajax";
import { remoteRoutes } from "../../../../data/constants";
import { toastMessages, overrideToastDefaults } from "../../../../data/toastDefaults";
import { buildUrl } from "../../../../utils/queryBuilder";

interface Props {
    item: IInputMovement | null
}

const DeleteInputMovementForm = ({ item }: Props) => {

    const [modalKey, setModalKey] = useState(Date.now());

    const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {

        const url = buildUrl(remoteRoutes.inputsService, `/input/movements/${item?.id}`)

        del(url, (response) => {

            CloseModal("delete-input-movement", () => {
                setSubmitting(false);
                resetForm();
            })

            setModalKey(Date.now());
            toast.success(toastMessages.default.success, overrideToastDefaults)
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    return (
        <Formik initialValues={{ item }} onSubmit={handleSubmit}>
            {({ handleSubmit, isSubmitting }) => {
                return <ModalWrapper
                    id="delete-input-movement"
                    title={`Delete Input Movement`}
                    size="md"
                    key={modalKey}
                    isDeleteAction={true}
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}>
                    <p>{`Are you sure you want to delete this Input Movement? This action cannot be undone.`}</p>
                </ModalWrapper>
            }}
        </Formik>
    )
}

export default DeleteInputMovementForm;