import { XSidebarWrapper } from "../../components/shared/XSidebarWrapper";
import MoveInputsForm from "./modals/MoveInputsForm";
import SellInputForm from "./modals/SellInputForm";

type Props = {
    close: () => void,
    show: boolean,
    selectedRow: any
}

const InputStockDetails = ({ close, show, selectedRow }: Props) => {

    return (
        <>
            <XSidebarWrapper close={close} show={show} width={35} height={75} marginTop={5}>
                <div className="d-flex justify-content-start w-100 align-items-center">
                    <div className="py-3 px-3 rounded-1 bg-primary">
                        <h2>{selectedRow?.inputName && selectedRow?.inputName[0].toUpperCase()}</h2>
                    </div>
                    <div className="px-3">
                        <h5>{selectedRow?.inputName}</h5>
                        <div className="fs-6 small">
                            <span className="badge text-bg-info">
                                {Number(selectedRow?.quantity).toLocaleString()} {selectedRow?.unit}
                            </span>
                        </div>
                    </div>
                    <div className={"w-75 text-end"}>
                        <div className="btn-group" role="group">
                            <button id="btnGroupDrop1" type="button"
                                className={`btn btn-outline-dark dropdown-toggle`}
                                data-bs-toggle="dropdown" aria-expanded="false">
                                Manage
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                <li>
                                    <a
                                        className="dropdown-item"
                                        data-bs-toggle={"modal"}
                                        data-bs-target={"#move-inputs"}
                                        href="#"
                                    >
                                        Move Inputs
                                    </a>
                                </li>
                                <li className="dropdown-divider"></li>
                                <li>
                                    <a className="dropdown-item"
                                        data-bs-toggle={"modal"}
                                        data-bs-target={"#sell-input"}
                                        href="#"
                                    >
                                        Sale Inputs
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="p-3 bg-gray rounded-3 mb-3">
                    <div className="row">

                        <div className="col-lg-6 col-12">
                            <strong className="small fw-semibold">Quantity</strong><br />
                            <span>{Number(selectedRow?.quantity).toLocaleString()} {selectedRow?.unit}</span>
                        </div>
                        <div className="col-lg-6 col-12">
                            <strong className="small fw-semibold">Collection Center</strong><br />
                            <span>{selectedRow?.collectionCentreName || "-"}</span>
                        </div>
                    </div>
                </div>
            </XSidebarWrapper>

            <SellInputForm selectedRow={selectedRow} />
            <MoveInputsForm selectedRow={selectedRow} />

        </>
    );

}

export default InputStockDetails