import { Outlet, Route, Routes } from "react-router-dom";
import { InputPurchases } from "./InputPurchases";
import { InputSales } from "./InputSales";
import { InputMovements } from "./InputMovements";
import InputStockLedger from "./InputStockLedger";

const InputDistributionPage = () => {
    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route index element={<>
                    <InputPurchases />
                </>} />
                <Route path='purchases' element={<InputPurchases />} />
                <Route path='stock' element={<InputStockLedger />} />
                <Route path='sales' element={<InputSales />} />
                <Route path='movements' element={<InputMovements />} />
                <Route path=':inputId/'
                    element={
                        <>
                            {/*<FarmerDetails />*/}
                        </>
                    }
                />
            </Route>
        </Routes>
    )
}

export default InputDistributionPage