import {Formik, Field} from "formik";
import {CloseModal} from "../../../../_theme/helpers/components/modalHelpers";
import {ModalWrapper} from "../../../components/shared/ModalWrapper";
import {XTextField} from "../../../components/shared/forms/XTextField";
import {IInputDistribution} from "../interfaces/IInputDistribution";
import React, {useEffect, useState} from "react";
import {buildUrl} from "../../../../utils/queryBuilder";
import {remoteRoutes} from "../../../../data/constants";
import {get, put} from "../../../../utils/ajax";
import {useDispatch} from "react-redux";
import {INPUTS_DISTRIBUTION_CONSTANTS} from "../../../../data/redux/inputs/inputsReducer";
import toast from "react-hot-toast";
import {overrideToastDefaults, toastMessages} from "../../../../data/toastDefaults";
import {IHub, IInput, IUnit} from "../../settings/interfaces/IHub";
import {authService} from "../../../../data/oidc/AuthService";
import {XAutoComplete, IOption} from "../../../components/shared/forms/XAutoComplete";
import {InputAdornment} from "@mui/material";
import {IdName} from "../../settings/inputs/interfaces/IInput";
import {IApiResponse} from "../../../interfaces/IApiResponse";
import {SETTINGS_CONSTANTS} from "../../../../data/redux/settings/settingsReducer";
import {InputSaleSchema} from "../schemas/InputSaleSchema";

type Props = {
    initialValues: IInputDistribution | null
}

const EditInputSaleForm = ({initialValues}: Props) => {

    const dispatch = useDispatch();

    const [modalKey, setModalKey] = useState(Date.now());
    const [inputs, setInputs] = useState<any[] | undefined>(undefined)
    const [units, setUnits] = useState<IUnit[]>([]);
    const options: IOption[] = units
        .map(u => ({
            id: u.id ?? '',
            label: u.name ?? ''
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const [inputSale, setInputSale] = useState<IInputDistribution>({
        ...initialValues,
        id: initialValues?.id,
        inputName: initialValues?.inputName,
        unitName: [] as IdName [],
        date: initialValues?.date ? new Date(initialValues.date as string).toISOString() : new Date().toISOString(),
    });

    useEffect(() => {

        handleFetchData();

        if (initialValues) {
            setInputSale({
                ...initialValues,
            });
        }

        const hub: IHub | undefined = authService.getHub()

        if (hub) {
            const {inputs} = hub;
            const sortedInputs = inputs?.map((input: IInput) => ({
                label: input.name,
                id: input.id,
            })).sort((a, b) => a.label.localeCompare(b.label));

            setInputs(sortedInputs);
        }
    }, [initialValues]);

    const handleFetchData = () => {
        if (remoteRoutes.onboardingService) {
            const url = buildUrl(remoteRoutes.onboardingService, '/units');
            get(url,
                (response: IApiResponse) => {
                    const {data} = response;
                    setUnits(data);
                },
                async (error) => {
                    // toast.error(toastMessages.default.fail, overrideToastDefaults);
                },
                () => {
                    dispatch({type: SETTINGS_CONSTANTS.STOP_FETCH});
                });
        }
    };

    const handleSubmit = (values: any, {resetForm, setSubmitting}: any) => {
        const data: IInputDistribution = {
            ...values,
            inputName: values.inputName.label,
            inputId: values.inputName.id,
            unitName: values.unitName.label,
            unitId: values.unitName.id,
        }

        const url = buildUrl(remoteRoutes.inputsService, `/input/sales/${values.id}`)

        put(url, data, (response) => {
            CloseModal("edit-input-sale", () => {
                setSubmitting(false);
                resetForm();
            })

            dispatch({
                type: INPUTS_DISTRIBUTION_CONSTANTS.UPDATE_DATA,
                payload: data
            })
            setModalKey(Date.now());
            toast.success(toastMessages.default.success, overrideToastDefaults)
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    };


    return (
        <Formik
            initialValues=
                {{
                    ...inputSale,
                    date: inputSale.date ? new Date(inputSale.date).toISOString() : new Date().toISOString(),
                    inputName: inputSale.inputName ? {
                        label: inputSale.inputName
                    } : null,
                    unitName: inputSale.unitName ? {
                        label: inputSale.unitName
                    } : null,
                }}
            onSubmit={handleSubmit}
            validationSchema={InputSaleSchema}
            enableReinitialize={true}
        >
            {({handleSubmit, isSubmitting, values, setFieldValue}) => {
                return (
                    <ModalWrapper
                        id="edit-input-sale"
                        title="Edit Input Sale"
                        size={"lg"}
                        key={modalKey}
                        submitting={isSubmitting}
                        handleSubmit={handleSubmit}
                    >
                        <div className="row">
                            <div className="col-lg-12">
                                <Field
                                    name="farmerName"
                                    as={XTextField}
                                    label="Farmer Name"
                                    disabled={isSubmitting}
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    component={XAutoComplete}
                                    options={inputs || []}
                                    getOptionLabel={(option: any) => (option ? option.label : '')}
                                    values={values.inputName}
                                    label="Input Name"
                                    name="inputName"
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    component={XAutoComplete}
                                    options={options || []}
                                    getOptionLabel={(option: any) => (option ? option.label : '')}
                                    values={values.unitName}
                                    label="Inputs Unit"
                                    name="unitName"
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type={"number"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label="Quantity"
                                    name="quantity"
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type={"number"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label="Total Amount Value"
                                    name="totalPrice"
                                    inputProps={{
                                        startAdornment: <InputAdornment position="start">UGX</InputAdornment>
                                    }}
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type={"number"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label="Total Amount Paid"
                                    name="amountPaid"
                                    inputProps={{
                                        startAdornment: <InputAdornment position="start">UGX</InputAdornment>
                                    }}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        const amountPaid = Number(e.target.value);
                                        setFieldValue("amountPaid", amountPaid);
                                        setFieldValue("balanceDue", Number(values.totalPrice) - amountPaid);
                                    }}
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type={"number"}
                                    disabled
                                    as={XTextField}
                                    label="Balance"
                                    name="balanceDue"
                                    inputProps={{
                                        startAdornment: <InputAdornment position="start">UGX</InputAdornment>
                                    }}
                                />
                            </div>
                        </div>
                    </ModalWrapper>
                );
            }}
        </Formik>
    );
}

export default EditInputSaleForm;
