import React, { useEffect, useState } from 'react'
import { PageHeaderLoader } from "../../components/shared/loaders/PageHeaderLoader";
import { TableFilterLoader } from "../../components/shared/loaders/TableFilterLoader";
import { TableLoader } from "../../components/shared/loaders/TableLoader";
import TableWrapper from "../../components/shared/TableWrapper";
import { IButtonProps, PageHeader } from "../../components/shared/PageHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faFilter, faSearch } from "@fortawesome/free-solid-svg-icons";
import XTable, { ITableColumn } from "../../components/shared/XTable";
import { search } from "../../../utils/ajax";
import toast from "react-hot-toast";
import { overrideToastDefaults, toastMessages } from "../../../data/toastDefaults";
import { useAuth } from "../auth";
import { remoteRoutes } from "../../../data/constants";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../data/types";
import { IApiResponse, IPaginationMetadata } from "../../interfaces/IApiResponse";
import { IBatchMovement } from "./interfaces/IBatch";
import { INVENTORY_BATCH_MOVEMENTS_CONSTANTS } from "../../../data/redux/inventory/batchMovementsReducer";
import useFetchBatch from "../../hooks/useFetchBatch";
import { BatchMovementDetails } from "./BatchMovementDetails";
import { InnerMenu } from "../../components/shared/InnerMenu";
import { Modules } from "../../interfaces/Enums";
import { printNaturalDateShort } from "../../../utils/dateHelpers";
import XPagination from "../../components/shared/XPagination";
import { IBreadcrumb } from '../../components/shared/Breadcrumbs';

const columns: ITableColumn[] = [
    {
        label: 'Date',
        id: 'date',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        label: 'Batch Number',
        id: 'batchNumber',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        label: 'Source',
        id: 'sourceCollectionCentreName',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        label: 'Destination',
        id: 'destinationCollectionCentreName',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        label: '',
        id: 'isDeleted',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    }
]
export const BatchMovements = () => {

    document.title = "Inventory > Movements"

    const { currentUser } = useAuth()
    const dispatch = useDispatch()

    const batchDetails = useSelector((state: IState) => state.batches)
    const batch = batchDetails.data[0]
    const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([])

    const { data, loading }: any = useSelector((state: IState) => state.batchMovements)
    const [selected, setSelected] = useState<any[]>([])

    const { fetchBatch } = useFetchBatch()
    const [selectedMovement, setSelectedMovement] = useState<IBatchMovement | undefined>(undefined);
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);

    const [page, setPage] = useState<number>(1);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [pagination, setPagination] = useState<IPaginationMetadata>({
        pageSize: 25,
        hasNextPage: false,
        currentPage: 1,
        hasPreviousPage: false,
        totalItems: 0,
        totalPages: 0
    });
    const [showActionButtons, setShowActionButtons] = useState<boolean>(false)
    const [batchedQuantity, setBatchedQuantity] = useState(0)

    const actionButtons: IButtonProps[] = [{
        label: `Move batch${selected?.length > 1 ? 'es' : ''}`,
        cssClass: "btn btn-primary",
        dataTarget: "#move-batch",
        dataToggle: "modal"
    }]

    const handleCheckRow = (batch: any) => {

        setSelected(prevSelected => {
            const newSelected = prevSelected ? [...prevSelected] : []

            const index = newSelected.indexOf(batch.id);
            if (index > -1) {
                // Remove the id if it's already in the array
                newSelected.splice(index, 1);
                setBatchedQuantity(prevQuantity => {
                    return prevQuantity - batch.quantity
                })
            } else {
                // Add the id if it's not in the array
                newSelected.push(batch.id);
                setBatchedQuantity(prevQuantity => {
                    return prevQuantity + batch.quantity
                })
            }

            return newSelected
        })
    };

    const handleClickRow = (item: any) => {
        setDrawerVisible(!drawerVisible)
        setSelectedMovement(item)
        fetchBatch(item.batchId)
    };

    const handleSelectAll = (rows: any[]) => {
        setSelected(prevSelected => {
            if (prevSelected.length === rows.length) {
                // If all rows are already selected, deselect all
                setBatchedQuantity(0)
                return [];
            } else {
                // Otherwise, select all rows
                const totalQuantity = rows.reduce((accumulator: number, item) => accumulator + item.quantity, 0)
                setBatchedQuantity(totalQuantity)
                return rows.map(row => row.id);
            }
        })
    };

    useEffect(() => {
        setShowActionButtons(selected && selected.length > 0)
    }, [selected])

    useEffect(() => {
        fetchBatches(page, pagination.pageSize)
    }, [page, searchTerm])

    const fetchBatches = (page: number, pageSize: number) => {

        dispatch({
            type: INVENTORY_BATCH_MOVEMENTS_CONSTANTS.LOADING_BATCH_MOVEMENTS,
            payload: true
        })

        const url = remoteRoutes.inventoryService + '/movements'
        const params = {
            hubId: currentUser?.hubId, page, pageSize
        }

        search(url, params,
            (response: IApiResponse) => {

                const { data, paginationMetadata } = response;
                setPagination(paginationMetadata);

                dispatch({
                    type: INVENTORY_BATCH_MOVEMENTS_CONSTANTS.FETCH_BATCH_MOVEMENTS,
                    payload: [...data]
                })

                // setLoading(false)
                toast.success(toastMessages.default.success, overrideToastDefaults)
            },
            (error) => {
                toast.error(toastMessages.default.fail, overrideToastDefaults)
            },
            () => {

            })
    }

    const handleSearch = (event: any) => {

    }

    const formattedDate = data.map((row: { date: string }) => ({
        ...row,
        date: printNaturalDateShort(row.date)
    }))

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inventory', url: undefined },
            { label: 'Movements', url: undefined }
        ])
    }, [])

    return (
        <>
            {loading && <>
                <PageHeaderLoader />
                <TableWrapper>
                    <TableFilterLoader />
                    <TableLoader />
                </TableWrapper>
            </>}
            {!loading && <>
                <PageHeader actionButtons={actionButtons} breadcrumbs={breadcrumbs}>
                    <InnerMenu module={Modules.Inventory} />
                </PageHeader>
                <TableWrapper>
                    <div className="d-flex w-100 align-items-center justify-content-between">
                        <div className="input-group w-25">
                            <input type="text" onKeyUp={(event) => handleSearch(event)}
                                className="form-control"
                                placeholder="Type to search..." />
                            <span className="input-group-text" id="addon-wrapping">
                                <FontAwesomeIcon icon={faSearch} />
                            </span>
                        </div>

                        <div className="action-buttons">

                            {showActionButtons &&
                                actionButtons.map((button, index) => {
                                    return (<button
                                        data-bs-toggle={button.dataToggle}
                                        data-bs-target={button.dataTarget}
                                        className={`${button.cssClass} ${button.processing ? 'disabled' : ''}`}
                                        key={index}
                                        onClick={button.onClick}>
                                        {button.processing ? 'Please wait...' : button.label}
                                    </button>)
                                })
                            }

                            <button type="button" className="btn btn-sm btn-outline-secondary ms-2">
                                <FontAwesomeIcon icon={faFilter} className="text-orange" />
                                <span className="ms-2">Filters</span>
                            </button>
                            <button type="button" className="btn btn-sm btn-outline-secondary ms-2">
                                <FontAwesomeIcon icon={faEllipsisVertical} className="text-orange" />
                            </button>
                        </div>
                    </div>

                    <XTable
                        selected={selected}
                        onSelectAll={(rows) => handleSelectAll(rows)}
                        onRowClick={(row) => handleClickRow(row)}
                        onCheckRow={(row) => handleCheckRow(row)}
                        data={formattedDate}
                        columns={columns}
                        loading={loading} />

                    <XPagination
                        dataLength={data.length}
                        pagination={pagination}
                        currentPage={page}
                        setPage={(page) => setPage(page)} />

                </TableWrapper>

                <BatchMovementDetails batchMovement={selectedMovement} close={() => setDrawerVisible(false)} show={drawerVisible} batch={batch} />

            </>}
        </>
    )
}