import { Formik, Field, Form } from "formik";
import { CloseModal } from "../../../../_theme/helpers/components/modalHelpers";
import { ModalWrapper } from "../../../components/shared/ModalWrapper";
import { XTextField } from "../../../components/shared/forms/XTextField";
import { useDispatch } from "react-redux";
import { useAuth } from "../../auth";
import { buildUrl } from "../../../../utils/queryBuilder";
import { remoteRoutes } from "../../../../data/constants";
import moment from "moment/moment";
import toast from "react-hot-toast";
import { overrideToastDefaults, toastMessages } from "../../../../data/toastDefaults";
import { INPUTS_DISTRIBUTION_CONSTANTS } from "../../../../data/redux/inputs/inputsReducer";
import { get, post } from "../../../../utils/ajax";
import React, { useEffect, useState } from "react";
import { InputAdornment } from "@mui/material";
import { IInputDistribution } from "../interfaces/IInputDistribution";
import { IHub, IInput, IUnit, IFarmer } from "../../settings/interfaces/IHub";
import { IOption, XAutoComplete } from "../../../components/shared/forms/XAutoComplete";
import { authService } from "../../../../data/oidc/AuthService";
import { IApiResponse } from "../../../interfaces/IApiResponse";
import { SETTINGS_CONSTANTS } from "../../../../data/redux/settings/settingsReducer";
import { InputSaleSchema } from "../schemas/InputSaleSchema";

type Props = {
    selectedRow: any
}

const SellInputForm = ({ selectedRow }: Props) => {

    const dispatch = useDispatch();
    const { currentUser } = useAuth()

    const [modalKey, setModalKey] = useState(Date.now());
    const [inputs, setInputs] = useState<any[] | undefined>(undefined)
    const [units, setUnits] = useState<IUnit[]>([]);
    const [farmers, setFarmers] = useState<IFarmer[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const options: IOption[] = units
        .map(u => ({
            id: u.id ?? '',
            label: u.name ?? ''
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const farmerList: IOption[] = farmers
        .map(f => ({
            id: f.id ?? '',
            label: f.fullname ?? ''
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const initialValues = {
        farmerName: '',
        inputId: selectedRow?.inputId,
        inputName: selectedRow?.inputName,
        quantity: selectedRow?.quantity,
        totalPrice: '',
        amountPaid: '',
        balanceDue: 0,
        unitName: selectedRow?.unit,
        unitId: selectedRow?.unitId,
        collectionCentreName: selectedRow?.collectionCentreName,
        collectionCentreId: selectedRow?.collectionCentreId,
    }

    useEffect(() => {

        setLoading(true);
        handleFetchUnits();
        handleFetchFarmers();

        const hub: IHub | undefined = authService.getHub()

        if (hub) {
            const { inputs } = hub;
            const sortedInputs = inputs?.map((input: IInput) => ({
                label: input.name,
                id: input.id,
            })).sort((a, b) => a.label.localeCompare(b.label));
            setInputs(sortedInputs);
        }
    }, []);

    const handleFetchUnits = () => {
        if (remoteRoutes.onboardingService) {
            const url = buildUrl(remoteRoutes.onboardingService, '/units');
            get(url, (response: IApiResponse) => {
                const { data } = response;
                setUnits(data);
            }, (error) => {
            }, () => {
                dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
            });
        }
    };

    const handleFetchFarmers = () => {
        if (remoteRoutes.profiles) {
            const hubId = currentUser?.hubId;
            let params = { hubId };
            const url = buildUrl(remoteRoutes.profiles, '/farmers', params);
            get(url, (response: IApiResponse) => {
                const { data } = response;
                setFarmers(data);
            }, (error) => {
            }, () => {
                dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
            });
        }
    };

    // const handleFetchFarmers = () => {
    //     if (remoteRoutes.profiles) {
    //         const hubId = currentUser?.hubId;
    //
    //         // Check if farmers are already saved in localStorage
    //         const savedFarmers = localStorage.getItem('farmers');
    //         if (savedFarmers) {
    //             setFarmers(JSON.parse(savedFarmers));
    //             return; // Skip fetching if data is already saved
    //         }
    //
    //         let allFarmers: IFarmer[] = [];
    //         let currentPage = 1;
    //         let totalPages = 1;
    //
    //         const fetchFarmers = (page: number) => {
    //             let params = {hubId, page, limit: 10};
    //             const url = buildUrl(remoteRoutes.profiles, '/farmers', params);
    //
    //             get(
    //                 url,
    //                 (response: IApiResponse) => {
    //                     const {data, paginationMetadata} = response;
    //
    //                     allFarmers = [...allFarmers, ...data];
    //
    //                     // Update the state and localStorage progressively
    //                     setFarmers((prevFarmers) => {
    //                         const updatedFarmers = [...prevFarmers, ...data];
    //                         localStorage.setItem('farmers', JSON.stringify(updatedFarmers));
    //                         return updatedFarmers;
    //                     });
    //
    //                     totalPages = paginationMetadata?.totalPages || 1;
    //
    //                     // Fetch next page if more data is available
    //                     if (currentPage < totalPages) {
    //                         currentPage++;
    //                         fetchFarmers(currentPage);
    //                     } else {
    //                         dispatch({type: SETTINGS_CONSTANTS.STOP_FETCH});
    //                     }
    //                 },
    //                 (error) => {
    //                     console.error('Error fetching farmers:', error);
    //                     dispatch({type: SETTINGS_CONSTANTS.STOP_FETCH});
    //                 }
    //             );
    //         };
    //
    //         fetchFarmers(currentPage); // Start fetching
    //     }
    // };

    const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {
        const url = buildUrl(remoteRoutes.inputsService, `/input/sales`)

        const typeOfSale = values.amountPaid === 0
            ? 2
            : values.amountPaid === values.totalPrice
                ? 1
                : values.amountPaid < values.totalPrice
                    ? 3
                    : 1;

        const data: IInputDistribution = {
            agentId: values.agentId ?? null,
            agentName: values.agentName ?? null,
            amountPaid: Number(values.amountPaid) ?? null,
            balanceDue: Number(values.balanceDue) ?? null,
            collectionCentreId: values.collectionCentreId ?? selectedRow?.collectionCentreId,
            collectionCentreName: values.collectionCentreName ?? selectedRow?.collectionCentreName,
            date: moment().toISOString(),
            farmerId: values.farmerName.id,
            farmerName: values.farmerName.label,
            hubId: currentUser?.hubId,
            inputName: values.inputName.label ?? selectedRow?.inputName,
            inputId: values.inputName.id ?? selectedRow?.inputId,
            pictureOfReceipt: null,
            quantity: Number(values.quantity),
            saleOrigin: 1,
            totalPrice: Number(values.totalPrice),
            typeOfSale: typeOfSale,
            unitName: values.unitName.label ?? selectedRow?.unit,
            unitId: values.unitName.id,
            unitPrice: Number(values.totalPrice) / Number(values.quantity),
        }

        post(url, data, (response) => {

            CloseModal("sell-input", () => {
                setSubmitting(false);
                resetForm();
            })
            dispatch({
                type: INPUTS_DISTRIBUTION_CONSTANTS.POST_DATA,
                payload: data
            })
            setModalKey(Date.now());
            toast.success(toastMessages.default.success, overrideToastDefaults)
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    return (
        <Formik
            initialValues={{
                ...initialValues,
                inputName: initialValues.inputName ? {
                    label: initialValues.inputName
                } : null,
                unitName: initialValues.unitName ? {
                    label: initialValues.unitName
                } : null,
                collectionCentreId: initialValues.collectionCentreId
            }}
            enableReinitialize={true}
            validate={(values) => {
                const errors: any = {};
                if (values.quantity > selectedRow.quantity) {
                    errors.quantity = `Quantity cannot exceed ${selectedRow.quantity}`;
                }
                return errors;
            }}
            onSubmit={handleSubmit}
            validationSchema={InputSaleSchema}
        >

            {({ handleSubmit, isSubmitting, values, setFieldValue }) => {
                return <ModalWrapper
                    id="sell-input"
                    title={`Sell ${initialValues.inputName}`}
                    size={"lg"}
                    submitting={isSubmitting}
                    key={modalKey}
                    handleSubmit={handleSubmit}>
                    <Form>

                    </Form>
                    <div className="row">
                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                component={XAutoComplete}
                                options={farmerList || []}
                                getOptionLabel={(option: any) => (option ? option.label : '')}
                                label="Farmer Name"
                                name="farmerName"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled
                                component={XAutoComplete}
                                options={inputs || []}
                                getOptionLabel={(option: any) => (option ? option.label : '')}
                                values={values.inputName}
                                label="Input Name"
                                name="inputName"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                component={XAutoComplete}
                                options={options || []}
                                getOptionLabel={(option: any) => (option ? option.label : '')}
                                values={values.unitName}
                                label="Inputs Unit"
                                name="unitName"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"number"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Quantity"
                                name="quantity"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"number"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Total Amount Value"
                                name="totalPrice"
                                inputProps={{
                                    startAdornment: <InputAdornment position="start">UGX</InputAdornment>
                                }}
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"number"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Total Amount Paid"
                                name="amountPaid"
                                inputProps={{
                                    startAdornment: <InputAdornment position="start">UGX</InputAdornment>
                                }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const amountPaid = Number(e.target.value);
                                    setFieldValue("amountPaid", amountPaid);
                                    setFieldValue("balanceDue", Number(values.totalPrice) - amountPaid);
                                }}
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"number"}
                                disabled
                                as={XTextField}
                                label="Balance"
                                name="balanceDue"
                                inputProps={{
                                    startAdornment: <InputAdornment position="start">UGX</InputAdornment>
                                }}
                            />
                        </div>
                    </div>
                </ModalWrapper>
            }}
        </Formik>
    )
}

export default SellInputForm;