import {ApplicationInsights} from "@microsoft/applicationinsights-web";
import {ReactPlugin} from "@microsoft/applicationinsights-react-js";

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: 'e0ab9640-6eb9-4039-b38f-16ee96f4da3e', // Replace with your key
        extensions: [reactPlugin],
        enableAutoRouteTracking: true, // Automatically track route changes
    },
});

appInsights.loadAppInsights();

export { appInsights, reactPlugin };