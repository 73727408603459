import { XSidebarWrapper } from "../../components/shared/XSidebarWrapper";
import React, { useEffect } from "react";
import { IHeaderButtonProps } from "../../components/shared/SettingsPageHeader";
import DeleteInputSaleForm from "./modals/DeleteInputSaleForm";
import EditInputSaleForm from "./modals/EditInputSaleForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCircleHalfStroke, faMultiply } from "@fortawesome/free-solid-svg-icons";
import { IInputDistribution } from "./interfaces/IInputDistribution";
import { useLocation } from "react-router-dom";

type Props = {
    close: () => void,
    show: boolean,
    selectedRow: IInputDistribution | null,
}

const InputDetails = ({ close, show, selectedRow }: Props) => {
    const location = useLocation();
    const actionButtons: IHeaderButtonProps[] = [
        {
            label: "Delete",
            cssClass: "btn btn-outline-secondary",
            dataTarget: "#delete-input-sale",
            dataToggle: "modal"
        }, {
            label: "Edit",
            cssClass: "btn btn-primary",
            dataTarget: "#edit-input-sale",
            dataToggle: "modal"
        },
    ];

    const initialValues: IInputDistribution = {
        ...selectedRow
    };

    useEffect(() => {

    }, []);

    return (
        <>
            <XSidebarWrapper close={close} show={show} width={35}>
                <div className="d-flex justify-content-start w-100 align-items-center">
                    <div className="py-3 px-3 rounded-1 bg-primary">
                        <h2>{selectedRow?.inputName && selectedRow?.inputName[0].toUpperCase()}</h2>
                    </div>
                    <div className="row p-2">
                        <div className="px-3">
                            <h5>{selectedRow?.inputName}</h5>
                            <div
                                className="small">UGX {Number(selectedRow?.totalPrice).toLocaleString()}
                            </div>
                        </div>
                        <div className="fs-6 d-flex justify-content-between w-100">
                            <span className="badge text-bg-info">
                                {Number(selectedRow?.quantity).toLocaleString() + " " + selectedRow?.unitName}
                            </span>
                            {selectedRow?.typeOfSale === 1 && (
                                <>
                                    <span className="badge bg-success">
                                        Fully Paid
                                        <FontAwesomeIcon icon={faCheck} style={{ paddingLeft: 5 }} />
                                    </span>
                                </>
                            )}
                            {selectedRow?.typeOfSale === 2 && (
                                <>
                                    <span className="badge bg-danger">
                                        On Credit
                                        <FontAwesomeIcon icon={faMultiply} style={{ paddingLeft: 5 }} />
                                    </span>
                                </>
                            )}
                            {selectedRow?.typeOfSale === 3 && (
                                <>
                                    <span className="badge bg-orange">
                                        Partial Payment
                                        <FontAwesomeIcon icon={faCircleHalfStroke} style={{ paddingLeft: 5 }} />
                                    </span>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <hr />
                <div className="p-3 bg-gray rounded-3 mb-2">
                    <div className="row">
                        <div className="col-lg-6 col-12 mb-4">
                            <strong className="small fw-semibold">Date of Distribution</strong><br />
                            <span>{selectedRow?.date}</span>
                        </div>
                        <div className="col-lg-6 col-12 mb-4">
                            <strong className="small fw-semibold">Input Category</strong><br />
                            <span>{"-"}</span>
                        </div>
                        <div className="col-lg-6 col-12">
                            <strong className="small fw-semibold">Unit Price</strong><br />
                            <span>UGX {Number(selectedRow?.unitPrice).toLocaleString()}</span>
                        </div>
                        <div className="col-lg-6 col-12">
                            <strong className="small fw-semibold">Collection Center</strong><br />
                            <span>{selectedRow?.collectionCentreName || "-"}</span>
                        </div>
                    </div>
                </div>

                <div className="p-3 bg-gray rounded-3 mb-2">
                    <div className="row">
                        <div className="col-lg-6 col-12 mb-4">
                            <span className="small fw-semibold">Farmer Name</span><br />
                            <span>{selectedRow?.farmerName || "-"}</span>
                        </div>
                        <div className="col-lg-6 col-12 mb-4">
                            <strong className="small fw-semibold">Farmer Contact</strong><br />
                            <span>{"-"}</span>
                        </div>
                        <div className="col-lg-6 col-12">
                            <strong className="small fw-semibold">Farmer Group</strong><br />
                            <span>{"-"}</span>
                        </div>
                        <div className="col-lg-6 col-12">
                            <strong className="small fw-semibold">Agent</strong><br />
                            <span>{selectedRow?.agentName || "-"}</span>
                        </div>
                    </div>
                </div>

                {location.pathname.includes("/farmers") ?
                    <></> :
                    <div className="row justify-content-end">
                        <div className="d-flex justify-content-end gap-3 w-50">
                            {
                                actionButtons.map((button, index) => {
                                    return (
                                        <button
                                            key={index}
                                            className={button.cssClass}
                                            data-bs-toggle={button.dataToggle}
                                            data-bs-target={button.dataTarget}
                                            onClick={() => button.onClick}>
                                            {button.label}
                                        </button>
                                    )
                                })
                            }
                        </div>
                    </div>
                }
            </XSidebarWrapper>
            <DeleteInputSaleForm item={selectedRow} />
            <EditInputSaleForm initialValues={selectedRow} />
        </>
    )
}

export default InputDetails;