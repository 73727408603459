import {Formik} from "formik";
import {CloseModal} from "../../../../_theme/helpers/components/modalHelpers";
import {ModalWrapper} from "../../../components/shared/ModalWrapper";
import {IInputDistribution} from "../interfaces/IInputDistribution";
import {useState} from "react";
import {buildUrl} from "../../../../utils/queryBuilder";
import {remoteRoutes} from "../../../../data/constants";
import {del} from "../../../../utils/ajax";
import toast from "react-hot-toast";
import {overrideToastDefaults, toastMessages} from "../../../../data/toastDefaults";

interface Props {
    item: IInputDistribution | null
}

const DeleteInputSaleForm = ({item}: Props) => {

    const [modalKey, setModalKey] = useState(Date.now());

    const handleSubmit = (values: any, {resetForm, setSubmitting}: any) => {

        const url = buildUrl(remoteRoutes.inputsService, `/input/sales/${item?.id}`)

        del(url, (response) => {

            CloseModal("delete-input-sale", () => {
                setSubmitting(false);
                resetForm();
            })

            setModalKey(Date.now());
            toast.success(toastMessages.default.success, overrideToastDefaults)
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    return (
        <Formik
            initialValues={{item}}
            enableReinitialize={true}
            onSubmit={handleSubmit}
        >
            {({handleSubmit, isSubmitting}) => {
                return <ModalWrapper
                    id="delete-input-sale"
                    title={`Delete Input Stock`}
                    size="md"
                    isDeleteAction={true}
                    submitting={isSubmitting}
                    key={modalKey}
                    handleSubmit={handleSubmit}>
                    <p>{`Are you sure you want to delete this Input Sale? This action cannot be undone.`}</p>
                </ModalWrapper>
            }}
        </Formik>
    )
}

export default DeleteInputSaleForm;