import {IInputDistribution} from "../../../app/modules/input-distribution/interfaces/IInputDistribution";

export const INPUTS_DISTRIBUTION_CONSTANTS = {
    STOP_FETCH: "STOP_FETCH",
    FETCH_DATA: "FETCH_DATA",
    POST_DATA: "POST_DATA",
    UPDATE_DATA: "UPDATE_DATA",
    GET_DETAILS: "GET_DETAILS",
    LOADING_DATA: "LOADING_DATA",
    SET_SORT_PARAMS: "SET_SORT_PARAMS",
    FILTER_DATA: "FILTER_DATA",
    RESET_FILTER: "RESET_FILTER",
    PAGINATION: "PAGINATION",
}

export interface IInputsDistributionState {
    loading: boolean
    data: IInputDistribution[]
    filter: {
        show: boolean,
        data: IInputDistribution[],
        range: {
            startDate: string,
            endDate: string
        }
        key: string,
        pagination: {
            itemsPerPage: number,
            activePage: number,
            isLastPage: boolean
        }
    }
    sorting: {
        order: string
        column: string
    }
    reset: boolean
}

const initialState: IInputsDistributionState = {
    loading: false,
    data: [],
    filter: {
        key: '',
        show: false,
        data: [],
        range: {
            startDate: '',
            endDate: ''
        },
        pagination: {
            itemsPerPage: 10,
            activePage: 1,
            isLastPage: false
        }
    },
    sorting: {
        order: 'asc',
        column: ''
    },
    reset: false
}


export default function inputsReducer(state = initialState, action: any) {
    switch (action.type) {

        case INPUTS_DISTRIBUTION_CONSTANTS.FILTER_DATA:
            return {...state, ...action.payload, show: !state.filter.show}

        case INPUTS_DISTRIBUTION_CONSTANTS.RESET_FILTER:
            initialState.filter.show = true
            return {...initialState, reset: true}

        case INPUTS_DISTRIBUTION_CONSTANTS.SET_SORT_PARAMS:
            return {...state, ...action.payload}

        case INPUTS_DISTRIBUTION_CONSTANTS.PAGINATION:
            return {...state, ...action.payload}

        case INPUTS_DISTRIBUTION_CONSTANTS.FETCH_DATA: {
            return {...state, 
                data: action.payload, 
                loading: false, 
                reset: false}
        }

        case INPUTS_DISTRIBUTION_CONSTANTS.POST_DATA: {
            const input: IInputDistribution = action.payload
            return {...state, data: [input, ...state.data]}
        }

        case INPUTS_DISTRIBUTION_CONSTANTS.LOADING_DATA: {
            return {...state, loading: action.payload}
        }

        case INPUTS_DISTRIBUTION_CONSTANTS.GET_DETAILS: {
            return {...state, requestDetails: action.payload}
        }

        case INPUTS_DISTRIBUTION_CONSTANTS.STOP_FETCH: {
            return {...state, loading: false}
        }

        default: {
            return state
        }
    }
}
