import React, { useState } from 'react';
import { useField } from 'formik';
import { InputProps, TextField } from '@mui/material';

// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";
import { date } from "yup";

interface Props {
    label: string
    placeholder?: string
    name: string
    defaultValue?: string
    disabled: boolean
    type: string
    inputProps?: InputProps
}

export const XDatePicker = ({ label, type = "text", disabled = false, inputProps, ...props }: Props) => {

    const [field, meta, helpers] = useField(props);
    const { setValue } = helpers;

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>

            <DatePicker
                label={label}
                {...field}
                {...props}
                sx={{
                    '& label.Mui-focused': {
                        color: '#1C5838'
                    },
                    '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                            borderColor: '#1C5838'
                        }
                    }
                }}
                value={field.value}
                disabled={disabled}
                onChange={(date) => setValue(date)}
                className="form-control mb-4"
                slotProps={{ textField: { variant: "standard" } }}
            />
        </LocalizationProvider>
    );
};