import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { PageHeader } from "../../components/shared/PageHeader";
import { PageHeaderLoader } from "../../components/shared/loaders/PageHeaderLoader";
import TableWrapper from "../../components/shared/TableWrapper";
import XTable, { ITableColumn } from "../../components/shared/XTable";
import XPagination from "../../components/shared/XPagination";
import { IApiResponse, IPaginationMetadata } from "../../interfaces/IApiResponse";
import { remoteRoutes } from "../../../data/constants";
import { buildUrl } from "../../../utils/queryBuilder";
import { get } from "../../../utils/ajax";
import { overrideToastDefaults, toastMessages } from "../../../data/toastDefaults";
import { useAuth } from "../auth";
import { IInputsDistributionState, INPUTS_DISTRIBUTION_CONSTANTS } from "../../../data/redux/inputs/inputsReducer";
import { IState } from "../../../data/types";
import { IInputDistribution } from "./interfaces/IInputDistribution";
import InputDetails from "./InputDetails";
import { InnerMenu } from "../../components/shared/InnerMenu";
import { Modules } from "../../interfaces/Enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { printNaturalDateShort } from "../../../utils/dateHelpers";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { IBreadcrumb } from "../../components/shared/Breadcrumbs";

const columns: ITableColumn[] = [
    { label: "Date of Sale", isDate: false, isNumberFormat: false, id: "date", textAlign: "text-start" },
    { label: "Farmer Name", id: "farmerName", textAlign: "text-start" },
    { label: "Input Name", id: "inputName", textAlign: "text-start" },
    { label: "Quantity", isNumberFormat: true, id: "quantity", textAlign: "text-end" },
    { label: "", isNumberFormat: false, id: "unitName", textAlign: "text-start" },
    { label: "Total Price", isNumberFormat: true, id: "totalPrice", textAlign: "text-end" },
    { label: "Amount Paid", isNumberFormat: true, id: "amountPaid", textAlign: "text-end" },
    { label: "Balance", isNumberFormat: true, id: "balanceDue", textAlign: "text-end" }
];

interface Props {
    showHeader?: boolean
}

export const InputSales = ({ showHeader = true }: Props) => {

    document.title = "Inputs > Sales"

    const dispatch = useDispatch();
    const location = useLocation();
    const urlParams = useParams()
    const { currentUser } = useAuth();

    const [selectedRow, setSelectedRow] = useState<IInputDistribution | null>(null);
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
    const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([])

    const [page, setPage] = useState<number>(1);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [pagination, setPagination] = useState<IPaginationMetadata>({
        pageSize: 15,
        hasNextPage: false,
        currentPage: 1,
        hasPreviousPage: false,
        totalItems: 0,
        totalPages: 0
    });

    const { data, loading }: IInputsDistributionState = useSelector((state: IState) => state.inputs);

    const handleSearch = (event: any) => {
        if (event.target.value === '') {
            setSearchTerm(undefined);
        }

        if (event.keyCode === 13) {
            setSearchTerm(event.target.value);
        }
        setPage(1);
    };

    const setLoading = (state: boolean) => {
        dispatch({
            type: INPUTS_DISTRIBUTION_CONSTANTS.LOADING_DATA,
            payload: state
        });
    };

    useEffect(() => {
        setLoading(true);
        handleFetchData(page, pagination.pageSize);
    }, [page, searchTerm]);

    const handleFetchData = (page: number, pageSize: number) => {
        const hubId = currentUser?.hubId;
        const farmerId = urlParams.farmerId;

        let params: any = { page, pageSize };

        if (location.pathname.includes("/farmers")) {
            params.farmerId = farmerId;
        } else {
            params.hubId = hubId;
        }

        if (searchTerm) {
            params.searchTerm = searchTerm;
        }

        const url = buildUrl(remoteRoutes.inputsService, '/input/sales', params);

        get(url,
            (response: IApiResponse) => {
                const { data, paginationMetadata } = response;
                setPagination(paginationMetadata);

                dispatch({
                    type: INPUTS_DISTRIBUTION_CONSTANTS.FETCH_DATA,
                    payload: data
                });

                toast.success(toastMessages.default.success, overrideToastDefaults);
            },
            (error) => {
                toast.error(toastMessages.default.fail, overrideToastDefaults);
            },
            () => {
                dispatch({ type: INPUTS_DISTRIBUTION_CONSTANTS.STOP_FETCH });
                setLoading(false);
            });
    };
    const handleRowClick = (item: any) => {
        setDrawerVisible(true)
        setSelectedRow(item)
    }

    const formattedDate = data.map((row: any) => ({
        ...row,
        date: printNaturalDateShort(row.date)
    }))

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inputs', url: undefined },
            { label: 'Sales', url: undefined }
        ])
    }, [])

    return (
        <>
            {showHeader && loading && <PageHeaderLoader />}
            {showHeader && !loading &&
                <>
                    <PageHeader breadcrumbs={breadcrumbs}>
                        <InnerMenu module={Modules.Inputs} />
                    </PageHeader>
                </>
            }

            <TableWrapper>
                <div className="d-flex w-100 align-items-center justify-content-between">
                    <div className="input-group w-25">
                        <input type="text" onKeyUp={(event) => handleSearch(event)}
                            className="form-control"
                            placeholder="Type to search..." />
                        <span className="input-group-text" id="addon-wrapping">
                            <FontAwesomeIcon icon={faSearch} />
                        </span>
                    </div>
                </div>
                <XTable
                    checked={false}
                    selected={[]}
                    onRowClick={(item) => handleRowClick(item)}
                    data={formattedDate}
                    columns={columns}
                    loading={loading}
                    onCheckRow={handleRowClick}
                />
                <XPagination currentPage={page} pagination={pagination} dataLength={data.length} setPage={setPage} />
            </TableWrapper>
            <InputDetails close={() => setDrawerVisible(false)} show={drawerVisible} selectedRow={selectedRow} />
        </>
    )
}
