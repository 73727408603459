import React, { useEffect, useState } from "react";
import { faEllipsisVertical, faFilter, faSearch } from "@fortawesome/free-solid-svg-icons";
import { IButtonProps, PageHeader } from "../../components/shared/PageHeader";
import { PageHeaderLoader } from "../../components/shared/loaders/PageHeaderLoader";
import TableWrapper from "../../components/shared/TableWrapper";
import XTable, { ITableColumn } from "../../components/shared/XTable";
import XPagination from "../../components/shared/XPagination";
import { IApiResponse, IPaginationMetadata } from "../../interfaces/IApiResponse";
import { InnerMenu } from "../../components/shared/InnerMenu";
import { Modules } from "../../interfaces/Enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MoveInputsForm from "./modals/MoveInputsForm";
import InputMovementDetails from "./InputMovementDetails";
import { printNaturalDateShort } from "../../../utils/dateHelpers";
import { useDispatch, useSelector } from "react-redux";
import { IInputsDistributionState, INPUTS_DISTRIBUTION_CONSTANTS } from "../../../data/redux/inputs/inputsReducer";
import { IState } from "../../../data/types";
import { get } from "../../../utils/ajax";
import toast from "react-hot-toast";
import { remoteRoutes } from "../../../data/constants";
import { toastMessages, overrideToastDefaults } from "../../../data/toastDefaults";
import { buildUrl } from "../../../utils/queryBuilder";
import { useAuth } from "../auth";
import { IBreadcrumb } from "../../components/shared/Breadcrumbs";

const columns: ITableColumn[] = [
    {
        label: "Moving Date",
        isDate: false,
        id: "date",
        textAlign: "text-start"
    },
    {
        label: "Movement Number",
        id: "movementNumber",
        textAlign: "text-start"
    },
    {
        label: "Input Name",
        id: "inputName",
        textAlign: "text-start"
    },
    {
        label: "Quantity",
        isNumberFormat: true,
        id: "quantity",
        textAlign: "text-end"
    },
    {
        label: "",
        id: "unitName",
        textAlign: "text-start"
    },
    {
        label: "Source",
        id: "sourceCollectionCentreName",
        textAlign: "text-start"
    },
    {
        label: "Destination",
        id: "destinationCollectionCentreName",
        textAlign: "text-start"
    },
    {
        label: "Status",
        id: "movementStatus",
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: "text-start"
    }
];


export const InputMovements = () => {

    document.title = "Inputs > Movements"
    const dispatch = useDispatch();
    const { currentUser } = useAuth();
    const [selectedRow, setSelectedRow] = useState<string | null>(null);
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
    const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([])

    const [page, setPage] = useState<number>(1);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [pagination, setPagination] = useState<IPaginationMetadata>({
        pageSize: 15,
        hasNextPage: false,
        currentPage: 1,
        hasPreviousPage: false,
        totalItems: 0,
        totalPages: 0
    });

    const [showActionButtons, setShowActionButtons] = useState<boolean>(true)
    const { data, loading }: IInputsDistributionState = useSelector((state: IState) => state.inputs);

    const handleSearch = (event: any) => {
        if (event.target.value === '') {
            setSearchTerm(undefined);
        }

        if (event.keyCode === 13) {
            setSearchTerm(event.target.value);
        }
        setPage(1);
    };


    const handleRowClick = (item: any) => {
        setDrawerVisible(true)
        setSelectedRow(item)
    }

    const setLoading = (state: boolean) => {
        dispatch({
            type: INPUTS_DISTRIBUTION_CONSTANTS.LOADING_DATA,
            payload: state
        });
    };

    useEffect(() => {
        setLoading(true);
        handleFetchData(page, pagination.pageSize);
    }, [page, searchTerm]);

    const handleFetchData = (page: number, pageSize: number) => {
        if (remoteRoutes.profiles) {
            const hubId = currentUser?.hubId;
            let params: any = { hubId, page, pageSize };

            if (searchTerm) {
                params.searchTerm = searchTerm;
            }

            const url = buildUrl(remoteRoutes.inputsService, '/input/movements', params);
            get(url,
                (response: IApiResponse) => {
                    const { data, paginationMetadata } = response;
                    setPagination(paginationMetadata);

                    dispatch({
                        type: INPUTS_DISTRIBUTION_CONSTANTS.FETCH_DATA,
                        payload: data
                    });

                    toast.success(toastMessages.default.success, overrideToastDefaults);
                },
                async (error) => {
                    toast.error(toastMessages.default.fail, overrideToastDefaults);
                },
                () => {
                    dispatch({ type: INPUTS_DISTRIBUTION_CONSTANTS.STOP_FETCH });
                    setLoading(false);
                });
        }
    };

    const formattedDate = data.map((row: any) => ({
        ...row,
        date: printNaturalDateShort(row.date)
    }))

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inputs', url: undefined },
            { label: 'Movements', url: undefined }
        ])
    }, [])

    return (
        <>
            {loading && <PageHeaderLoader />}
            {!loading &&
                <>
                    <PageHeader breadcrumbs={breadcrumbs}>
                        <InnerMenu module={Modules.Inputs} />
                    </PageHeader>


                    <TableWrapper>
                        <div className="d-flex w-100 align-items-center justify-content-between">
                            <div className="input-group w-25">
                                <input type="text" onKeyUp={(event) => handleSearch(event)}
                                    className="form-control"
                                    placeholder="Type to search..." />
                                <span className="input-group-text" id="addon-wrapping">
                                    <FontAwesomeIcon icon={faSearch} />
                                </span>
                            </div>
                        </div>
                        <XTable
                            selected={[]}
                            checked={false}
                            onRowClick={(item) => handleRowClick(item)}
                            data={formattedDate}
                            columns={columns}
                            loading={loading}
                        />
                        <XPagination
                            currentPage={page}
                            pagination={pagination}
                            dataLength={data.length}
                            setPage={setPage}
                        />
                    </TableWrapper>

                    <InputMovementDetails
                        close={() => setDrawerVisible(false)}
                        show={drawerVisible}
                        selectedRow={selectedRow}
                    />
                </>
            }
        </>
    );
};
