import React, { useEffect, useState } from 'react'
import { PageHeaderLoader } from "../../components/shared/loaders/PageHeaderLoader";
import { TableFilterLoader } from "../../components/shared/loaders/TableFilterLoader";
import { TableLoader } from "../../components/shared/loaders/TableLoader";
import TableWrapper from "../../components/shared/TableWrapper";
import { IButtonProps, PageHeader } from "../../components/shared/PageHeader";
import XTable, { ITableColumn } from "../../components/shared/XTable";
import { useAuth } from "../auth";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../data/types";
import { CreateBatchForm } from "./modals/CreateBatchForm";
import useFetchPurchases from "../../hooks/useFetchPurchases";
import { InnerMenu } from "../../components/shared/InnerMenu";
import { IMenuItem } from "../../../utils/MainMenu";
import { InventoryInnerMenu } from "./InventoryInnerMenu";
import { useLocation, useParams } from "react-router-dom";
import { Modules } from "../../interfaces/Enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faFilter, faSearch } from "@fortawesome/free-solid-svg-icons";
import { IPurchaseProduct } from "./interfaces/IPurchase";
import { IIdNamePair } from "../settings/interfaces/IHub";
import { SellPurchasesForm } from "./modals/SellPurchasesForm";
import { DownloadCsvForm } from "./modals/DownloadCsvForm";
import { faFileImport } from "@fortawesome/free-solid-svg-icons/faFileImport";
import { ImportPurchasesForm } from "./modals/ImportPurchasesForm";
import XPagination from "../../components/shared/XPagination";
import { printNaturalDateShort } from "../../../utils/dateHelpers";
import { IBreadcrumb } from '../../components/shared/Breadcrumbs';

const calculateUnitPrice = (totalPrice: number, quantity: number): string => {
    if (!quantity || quantity === 0) return '0';

    const unitPrice = totalPrice / quantity;

    const formattedUnitPrice = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }).format(unitPrice);

    return unitPrice === 0 ? '0' : `UGX ${formattedUnitPrice}`;
};

const formatTotalAmount = (totalAmount: number) => {

    const formattedTotalPrice = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }).format(totalAmount);

    return totalAmount === 0 ? '0' : `UGX ${formattedTotalPrice}`;
}

export const PurchaseColumns: ITableColumn[] = [
    {
        label: 'Date of Purchase',
        id: 'date',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start',
    },
    {
        label: 'Farmer',
        id: 'farmerName',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        label: 'Product',
        id: 'productName',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        label: 'Quantity(kg)',
        id: 'quantity',
        link: undefined,
        isNumberFormat: true,
        isDate: false,
        textAlign: 'text-start',
    },
    {
        label: 'Cost',
        id: 'totalPrice',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start',
        formatter: row => formatTotalAmount(row.totalPrice)
    },
    {
        label: 'Unit Price',
        id: 'unitPrice',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start',
        formatter: (row: any) => calculateUnitPrice(row.totalPrice, row.quantity),
    },
    {
        label: 'Agent',
        id: 'agentName',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
]

interface Props {
    showHeader?: boolean
}

export const Purchases = ({ showHeader = true }: Props) => {

    document.title = "Inventory > Purchases"

    const { currentUser } = useAuth()
    const dispatch = useDispatch()
    const location = useLocation()

    const urlParams = useParams()

    const { fetchPurchases, pagination } = useFetchPurchases()
    const { data, loading }: any = useSelector((state: IState) => state.purchases)
    const [selected, setSelected] = useState<any[]>([])
    const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([])

    const [page, setPage] = useState<number>(1);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

    const [showActionButtons, setShowActionButtons] = useState<boolean>(false)
    const [batchedQuantity, setBatchedQuantity] = useState(0)

    const activePage = location.pathname.toLowerCase()

    const [subMenuItems, setSubMenuItems] = useState<IMenuItem[] | undefined>([]);
    const [selectedProduct, setSelectedProduct] = useState<IIdNamePair>({ id: '', name: '' });

    useEffect(() => {
        const items = InventoryInnerMenu({ activePage })
        setSubMenuItems(items)
    }, [activePage])

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inventory', url: undefined },
            { label: 'Purchases', url: undefined }
        ])
    }, [])
    
    const actionButtons: IButtonProps[] = [
        {
            label: `Create a batch`,
            cssClass: "btn btn-primary ms-2",
            dataTarget: "#create-batch",
            dataToggle: "modal"
        },
        {
            label: `Sell batch`,
            cssClass: "btn btn-success ms-2",
            dataTarget: "#sell-purchases",
            dataToggle: "modal"
        },
        {
            label: `Download CSV`,
            cssClass: "btn btn-secondary ms-2",
            dataTarget: "#download-csv",
            dataToggle: "modal"
        }
    ]

    const handleRowClick = (row: any) => {

        setSelected(prevSelected => {
            const newSelected = prevSelected ? [...prevSelected] : []

            const index = newSelected.indexOf(row.id);
            if (index > -1) {
                // Remove the id if it's already in the array
                newSelected.splice(index, 1);
                setBatchedQuantity(prevQuantity => {
                    return prevQuantity - row.quantity
                })
            } else {
                // Add the id if it's not in the array
                newSelected.push(row.id);
                setBatchedQuantity(prevQuantity => {
                    return prevQuantity + row.quantity
                })

                setSelectedProduct({ id: row.productId, name: row.productName })
            }

            return newSelected
        })
    };

    const handleSelectAll = (rows: any[]) => {
        setSelected(prevSelected => {
            if (prevSelected.length === rows.length) {
                // If all rows are already selected, deselect all
                setBatchedQuantity(0)
                return [];
            } else {
                // Otherwise, select all rows
                const totalQuantity = rows.reduce((accumulator: number, item) => accumulator + item.quantity, 0)
                setBatchedQuantity(totalQuantity)
                return rows.map(row => row.id);
            }
        })
    };

    useEffect(() => {
        setShowActionButtons(selected && selected.length > 0)
    }, [selected])

    useEffect(() => {
        const isFarmersPage = location.pathname.includes('farmers');
        const searchParams = isFarmersPage
            ? { farmerId: urlParams.farmerId }
            : { hubId: currentUser?.hubId };

        fetchPurchases(pagination.currentPage, pagination.pageSize, searchParams);
    }, [pagination.currentPage, searchTerm, location.pathname, urlParams.farmerId, currentUser?.hubId]);



    const handleSearch = (event: any) => {
        if (event.target.value === '') {
            setSearchTerm(undefined)
        }

        if (event.keyCode === 13) {
            setSearchTerm(event.target.value)
        }
    }

    const formattedData = data.map((row: { totalPrice: number; quantity: number; date: string, unitName: string }) => ({
        ...row,
        totalPrice: formatTotalAmount(row.totalPrice),
        date: printNaturalDateShort(row.date),
        unitPrice: row.quantity ? calculateUnitPrice(row.totalPrice, row.quantity) : 'N/A',
    }));

    return (
        <>
            {showHeader && loading && <>
                <PageHeaderLoader />
                <TableWrapper>
                    <TableFilterLoader />
                    <TableLoader />
                </TableWrapper>
            </>}
            {showHeader && !loading && <>

                <PageHeader actionButtons={actionButtons} breadcrumbs={breadcrumbs}>
                    <InnerMenu module={Modules.Inventory} />
                </PageHeader>
            </>

            }
            <TableWrapper>

                {/*<XSearchFilter onKeyUp={(event) => handleSearch(event)}/>*/}

                <div className="d-flex w-100 align-items-center justify-content-between">
                    <div className="input-group w-25">
                        <input type="text" onKeyUp={(event) => handleSearch(event)}
                            className="form-control"
                            placeholder="Type to search..." />
                        <span className="input-group-text" id="addon-wrapping">
                            <FontAwesomeIcon icon={faSearch} />
                        </span>
                    </div>

                    <div className="action-buttons">
                        {showHeader &&
                            <>
                                <button type="button"
                                    data-bs-toggle={"modal"}
                                    data-bs-target={"#import-purchases"}
                                    className="btn btn-sm btn-outline-dark ms-2">
                                    <FontAwesomeIcon icon={faFileImport} />
                                    <span className="ms-2">Import</span>
                                </button>
                            </>
                        }
                        {/*<button type="button" className="btn btn-sm btn-outline-secondary ms-2 me-2">*/}
                        {/*    <FontAwesomeIcon icon={faEllipsisVertical} className="text-orange"/>*/}
                        {/*</button>*/}

                        {showHeader && showActionButtons &&
                            actionButtons.map((button, index) => {
                                return (<button
                                    data-bs-toggle={button.dataToggle}
                                    data-bs-target={button.dataTarget}
                                    className={`${button.cssClass} ${button.processing ? 'disabled' : ''}`}
                                    key={index}
                                    onClick={button.onClick}>
                                    {button.processing ? 'Please wait...' : button.label}
                                </button>)
                            })
                        }

                    </div>
                </div>

                <XTable
                    selected={selected}
                    onSelectAll={(rows) => handleSelectAll(rows)}
                    onRowClick={(row) => handleRowClick(row)}
                    data={formattedData}
                    columns={PurchaseColumns}
                    loading={loading} />

                <XPagination
                    dataLength={data.length}
                    pagination={pagination}
                    currentPage={page}
                    setPage={(page) => setPage(page)} />

            </TableWrapper>

            <SellPurchasesForm purchases={selected} />
            <CreateBatchForm quantity={batchedQuantity} purchaseIds={selected} product={selectedProduct} />
            <DownloadCsvForm purchases={selected} />

            <ImportPurchasesForm />
        </>
    )
}