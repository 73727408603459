import { Formik, Field } from "formik";
import { CloseModal } from "../../../../_theme/helpers/components/modalHelpers";
import { ModalWrapper } from "../../../components/shared/ModalWrapper";
import { XTextField } from "../../../components/shared/forms/XTextField";
import { XDatePicker } from "../../../components/shared/forms/XDatePicker";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { authService } from "../../../../data/oidc/AuthService";
import { IHub, ICollectionCentre, IInput } from "../../settings/interfaces/IHub";
import { XAutoComplete } from "../../../components/shared/forms/XAutoComplete";
import moment from "moment";
import toast from "react-hot-toast";
import { post } from "../../../../utils/ajax";
import { remoteRoutes } from "../../../../data/constants";
import { INPUTS_DISTRIBUTION_CONSTANTS } from "../../../../data/redux/inputs/inputsReducer";
import { toastMessages, overrideToastDefaults } from "../../../../data/toastDefaults";
import { buildUrl } from "../../../../utils/queryBuilder";
import { IInputMovement } from "../interfaces/IInputMovement";
import { useAuth } from "../../auth";
import { InputMovementSchema } from "../schemas/InputMovementSchema";

type Props = {
    selectedRow: any
}

const MoveInputsForm = ({ selectedRow }: Props) => {
    const dispatch = useDispatch();
    const { currentUser } = useAuth()

    const [modalKey, setModalKey] = useState(Date.now());

    const [inputs, setInputs] = useState<any[] | undefined>(undefined)
    const [locations, setLocations] = useState<any[] | undefined>(undefined)

    const initialValues = {
        date: selectedRow?.date ? new Date(selectedRow.date as string).toISOString() : new Date().toISOString(),
        movementNumber: '',
        inputName: selectedRow?.inputName,
        quantity: selectedRow?.quantity,
        sourceCollectionCentreName: selectedRow?.collectionCentreName,
        sourceCollectionCentreId: selectedRow?.collectionCentreId,
        destinationCollectionCentreName: null,
        sourceAgentName: '',
        vehicleNumber: '',
        unitName: selectedRow?.unit,
        unitId: selectedRow?.unitId,
    }

    useEffect(() => {

        const hub: IHub | undefined = authService.getHub()

        if (hub) {
            const { collectionCentres } = hub
            const sortedCollectionCentres = collectionCentres?.map((center: ICollectionCentre) => ({
                label: center.name,
                id: center.id
            })).sort((a, b) => a.label.localeCompare(b.label));
            setLocations(sortedCollectionCentres)

            const { inputs } = hub;
            const sortedInputs = inputs?.map((input: IInput) => ({
                id: input.id,
                label: input.name,
            })).sort((a, b) => a.label.localeCompare(b.label));

            setInputs(sortedInputs);
        }
    }, [])

    const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {
        const url = buildUrl(remoteRoutes.inputsService, `/input/movements`)

        const data: IInputMovement = {
            ...values,
            hubId: currentUser?.hubId,
            date: moment().toISOString(),
            inputName: values.inputName.label,
            inputId: values.inputName.id ?? selectedRow?.inputId,
            destinationCollectionCentreId: values.destinationCollectionCentreName.id,
            destinationCollectionCentreName: values.destinationCollectionCentreName.label,
            sourceCollectionCentreId: values.sourceCollectionCentreName.id ?? selectedRow?.collectionCentreId,
            sourceCollectionCentreName: values.sourceCollectionCentreName.label ?? selectedRow?.collectionCentreName,
            typeOfMovement: 1,
            unitName: selectedRow?.unit,
            unitId: "4aa68d92-fe9c-4756-baee-33677b93f30d",
            sourceAgentId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        }

        post(url, data, (response) => {

            CloseModal("move-inputs", () => {
                setSubmitting(false);
                resetForm();
            })
            dispatch({
                type: INPUTS_DISTRIBUTION_CONSTANTS.POST_DATA,
                payload: data
            })
            setModalKey(Date.now());
            toast.success(toastMessages.default.success, overrideToastDefaults)
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    return (
        <Formik
            initialValues={{
                ...initialValues,
                date: dayjs(initialValues?.date),
                inputName: initialValues.inputName ? {
                    label: initialValues.inputName
                } : null,
                unitName: initialValues.unitName ? {
                    label: initialValues.unitName
                } : null,
                sourceCollectionCentreName: initialValues.sourceCollectionCentreName ? {
                    label: initialValues.sourceCollectionCentreName
                } : null,
            }}
            validate={(values) => {
                const errors: any = {};
                if (values.quantity > selectedRow.quantity) {
                    errors.quantity = `Quantity cannot exceed ${selectedRow.quantity}`;
                }
                return errors;
            }}
            validationSchema={InputMovementSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
        >
            {({ handleSubmit, isSubmitting, values }) => {
                return (
                    <ModalWrapper
                        id="move-inputs"
                        title={`Move ${initialValues.inputName}`}
                        size={"lg"}
                        key={modalKey}
                        submitting={isSubmitting}
                        handleSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    as={XDatePicker}
                                    label="Date"
                                    name="date"
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label="Movement Number"
                                    name="movementNumber"
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled
                                    component={XAutoComplete}
                                    options={inputs || []}
                                    getOptionLabel={(option: any) => (option ? option.label : '')}
                                    values={values.inputName}
                                    label="Input Name"
                                    name="inputName"
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type={"number"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label="Quantity"
                                    name="quantity"
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled
                                    component={XAutoComplete}
                                    options={locations || []}
                                    value={values.sourceCollectionCentreName}
                                    getOptionLabel={(option: any) => (option ? option.label : '')}
                                    label="Origin / Source"
                                    name="sourceCollectionCentreName"
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    component={XAutoComplete}
                                    options={locations || []}
                                    value={values.destinationCollectionCentreName}
                                    getOptionLabel={(option: any) => (option ? option.label : '')}
                                    label="Destination"
                                    name="destinationCollectionCentreName"
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label="Agent"
                                    name="sourceAgentName"
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label="Movers Number"
                                    name="vehicleNumber"
                                />
                            </div>
                        </div>
                    </ModalWrapper>
                )

            }}
        </Formik>
    )
}

export default MoveInputsForm;