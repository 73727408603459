import { ModalWrapper } from "../../../components/shared/ModalWrapper";
import { Field, Formik } from "formik";
import { XTextField } from "../../../components/shared/forms/XTextField";
import { post } from "../../../../utils/ajax";
import { buildUrl } from "../../../../utils/queryBuilder";
import { remoteRoutes } from "../../../../data/constants";
import toast from "react-hot-toast";
import { overrideToastDefaults, toastMessages } from "../../../../data/toastDefaults";
import moment from "moment";
import { useAuth } from "../../auth";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { INPUTS_DISTRIBUTION_CONSTANTS } from "../../../../data/redux/inputs/inputsReducer";
import { CloseModal } from "../../../../_theme/helpers/components/modalHelpers";
import { CreateBatchSchema } from "../schemas/CreateBatchSchema";
import { XTextArea } from "../../../components/shared/forms/XTextArea";
import { IOption, XAutoComplete } from "../../../components/shared/forms/XAutoComplete";
import { ICollectionCentre, IHub, IIdNamePair, IProduct } from "../../settings/interfaces/IHub";
import { authService } from "../../../../data/oidc/AuthService";
import { Autocomplete, Box, TextField } from "@mui/material";
import useFetchPurchases from "../../../hooks/useFetchPurchases";
import { IPurchase, IPurchaseProduct } from "../interfaces/IPurchase";
import { IState } from "../../../../data/types";
import { ITrainingsState } from "../../../../data/redux/trainings/trainingsReducer";
import { IPurchaseState } from "../../../../data/redux/inventory/purchasesReducer";
import { IdName } from "../../settings/inputs/interfaces/IInput";
import { IBuyer } from "../interfaces/IBuyer";
import { ID } from "../../../../_theme/helpers";
import { ISettingsState } from "../../../../data/redux/settings/settingsReducer";


type Props = {
    quantity: number
    purchaseIds: any[]
    product: IIdNamePair
}

export const CreateBatchForm = ({ quantity, purchaseIds, product }: Props) => {

    const dispatch = useDispatch()
    const { currentUser } = useAuth()
    const hub: any = authService.getHub()

    const { collectionCentres, products } = hub
    const productOptions: IIdNamePair[] = products?.map((product: IProduct) => ({ label: product.name, id: product.id }))
    const locations: IOption = collectionCentres?.map((center: ICollectionCentre) => ({
        label: center.name,
        id: center.id
    }))

    console.log('locations')
    console.log(locations)

    const productsList: IIdNamePair[] = products.map((p: IProduct) => ({
        id: p.id ?? '',
        name: p.name
    }));

    console.log(productsList)
    console.log(product)

    const initialValues = {
        batchNumber: '',
        location: [],
        product: product,
        description: ''
    }

    const handleSubmit = (values: any, resetForm: any) => {

        const url = buildUrl(remoteRoutes.inventoryService, '/batches')
        const data = {
            hubId: currentUser?.hubId,
            date: moment().toISOString(),
            unitId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            unitName: 'mt',
            productId: values.product.id,
            productName: values.product.name,
            batchingCollectionCentreId: values.location.id,
            batchingCollectionCentreName: values.location.label,
            quantity: quantity,
            purchaseIds: purchaseIds,
            batchNumber: values.batchNumber,
            description: values.description
        }

        console.log(data)

        post(url, data, (response) => {

            CloseModal("create-batch")

            dispatch({
                type: INPUTS_DISTRIBUTION_CONSTANTS.POST_DATA,
                payload: data
            })
            toast.success(toastMessages.default.success, overrideToastDefaults)
        }, (error) => {

            console.log(error)

            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    useEffect(() => {

    }, [])

    return (

        <Formik
            initialValues={initialValues}
            validationSchema={CreateBatchSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}>
            {({ handleSubmit, isSubmitting, touched, errors, values, setFieldValue }) => {

                return <ModalWrapper
                    id="create-batch"
                    title={"Create a Batch"}
                    size="md"
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}>
                    <Box>
                        <div className="row">
                            <div className="col-lg-6 col-12">
                                <Field
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label="Batch Number"
                                    name="batchNumber"
                                />
                            </div>

                            <div className="col-lg-6 col-12">
                                <Field name="product">
                                    {() => (
                                        <Autocomplete

                                            getOptionLabel={(option: IIdNamePair) => option.name}
                                            options={productsList}
                                            onChange={(event, value) => {
                                                setFieldValue('product', value);
                                            }}

                                            disabled={true}

                                            value={values.product}

                                            renderOption={(props, option) => (
                                                <li {...props} key={option.id}>
                                                    {option.name}
                                                </li>
                                            )}

                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Select a product"
                                                    variant="standard"
                                                    error={touched.product && Boolean(errors.product)}
                                                // helperText={touched.product && errors.product ? errors.product : ''}
                                                />
                                            )} />
                                    )}
                                </Field>
                            </div>

                            <div className="col-lg-12 col-12">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    component={XAutoComplete}
                                    options={locations}
                                    label="Where is the batch?"
                                    name="location"
                                    value={values.location}
                                />
                            </div>

                            <div className="col-lg-12">
                                <Field
                                    disabled={isSubmitting}
                                    component={XTextArea}
                                    rows={6}
                                    multiline={false}
                                    label="Description"
                                    name="description"
                                />
                            </div>

                        </div>
                    </Box>
                </ModalWrapper>

            }}
        </Formik>


    )
}

