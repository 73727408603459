import React, { useEffect, useState } from 'react'
import { PageHeaderLoader } from "../../components/shared/loaders/PageHeaderLoader";
import { TableFilterLoader } from "../../components/shared/loaders/TableFilterLoader";
import { TableLoader } from "../../components/shared/loaders/TableLoader";
import TableWrapper from "../../components/shared/TableWrapper";
import { IButtonProps, PageHeader } from "../../components/shared/PageHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import XTable, { ITableColumn } from "../../components/shared/XTable";
import { search } from "../../../utils/ajax";
import toast from "react-hot-toast";
import { overrideToastDefaults, toastMessages } from "../../../data/toastDefaults";
import { useAuth } from "../auth";
import { remoteRoutes } from "../../../data/constants";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../data/types";
import { INVENTORY_BATCHES_CONSTANTS } from "../../../data/redux/inventory/batchesReducer";
import { IApiResponse, IPaginationMetadata } from "../../interfaces/IApiResponse";
import { MoveBatchForm } from "./modals/MoveBatchForm";
import { BatchDetails } from "./BatchDetails";
import { IBatch } from "./interfaces/IBatch";
import { InnerMenu } from "../../components/shared/InnerMenu";
import { IMenuItem } from "../../../utils/MainMenu";
import { InventoryInnerMenu } from "./InventoryInnerMenu";
import { useLocation } from "react-router-dom";
import { Modules } from "../../interfaces/Enums";
import { CombineBatchesForm } from "./modals/CombineBatchesForm";
import { DownloadCsvForm } from "./modals/DownloadCsvForm";
import SellBatchSymosForm from "./modals/SellBatchSymosForm";
import { printNaturalDateShort } from "../../../utils/dateHelpers";
import XPagination from "../../components/shared/XPagination";
import { IBreadcrumb } from '../../components/shared/Breadcrumbs';

const columns: ITableColumn[] = [
    {
        label: 'Date',
        id: 'date',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        label: 'Batch Number',
        id: 'batchNumber',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        label: 'Current Location',
        id: 'currentCollectionCentreName',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        label: 'Product',
        id: 'productName',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        label: 'Quantity',
        id: 'quantity',
        link: undefined,
        isNumberFormat: true,
        isDate: false,
        textAlign: 'text-end'
    },
    {
        label: '',
        id: 'unitName',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        label: 'Status',
        id: 'currentStatus',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    // {
    //     label: 'Total Cost',
    //     id: 'totalPrice',
    //     link: undefined,
    //     isNumberFormat: false,
    //     isDate: false,
    //     textAlign: 'text-start'
    // }
]
export const Batches = () => {

    document.title = "Inventory > Batches"

    const { currentUser } = useAuth()
    const dispatch = useDispatch()

    const { data, loading }: any = useSelector((state: IState) => state.batches)
    const [selected, setSelected] = useState<any[]>([])
    const [selectedBatches, setSelectedBatches] = useState<any[]>([])
    const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([])

    const [selectedBatch, setSelectedBatch] = useState<IBatch | null>(null);
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);

    const [page, setPage] = useState<number>(1);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [pagination, setPagination] = useState<IPaginationMetadata>({
        pageSize: 25,
        hasNextPage: false,
        currentPage: 1,
        hasPreviousPage: false,
        totalItems: 0,
        totalPages: 0
    });
    const [showActionButtons, setShowActionButtons] = useState<boolean>(false)
    const [batchedQuantity, setBatchedQuantity] = useState(0)

    const actionButtons: IButtonProps[] = [
        {
            label: `Sell batch${selected?.length > 0 ? 'es' : ''}`,
            cssClass: "btn btn-sm btn-primary rounded-2 ms-2",
            dataTarget: "#sell-batch-symos",
            dataToggle: "modal"
        },
        {
            label: `Move batch${selected?.length > 0 ? 'es' : ''}`,
            cssClass: "btn btn-sm btn-outline-primary rounded-2 ms-2",
            dataTarget: "#move-batch",
            dataToggle: "modal"
        }]

    if (selected?.length > 0) {
        actionButtons.push({
            label: `Download CSV`,
            cssClass: "btn btn-secondary ms-2",
            dataTarget: "#download-csv",
            dataToggle: "modal"
        })
    }

    if (selected?.length > 1) {
        actionButtons.push({
            label: `Combine batches`,
            cssClass: "btn btn-sm btn-default rounded-2 ms-2",
            dataTarget: "#combine-batches",
            dataToggle: "modal"
        })
    }

    const location = useLocation()
    const activePage = location.pathname.toLowerCase()
    const [subMenuItems, setSubMenuItems] = useState<IMenuItem[] | undefined>([]);

    useEffect(() => {
        const items = InventoryInnerMenu({ activePage })
        setSubMenuItems(items)
    }, [activePage])

    const handleCheckRow = (batch: any) => {

        setSelected(prevSelected => {
            const newSelected = prevSelected ? [...prevSelected] : []

            const index = newSelected.indexOf(batch.id);
            if (index > -1) {
                // Remove the id if it's already in the array
                newSelected.splice(index, 1);
                setBatchedQuantity(prevQuantity => {
                    return prevQuantity - batch.quantity
                })
            } else {
                // Add the id if it's not in the array
                newSelected.push(batch.id);
                setBatchedQuantity(prevQuantity => {
                    return prevQuantity + batch.quantity
                })
            }

            return newSelected
        })

        setSelectedBatches(prevSelected => {
            const newSelected = prevSelected ? [...prevSelected] : []

            const index = newSelected.indexOf(batch.id);
            if (index > -1) {
                newSelected.splice(index, 1);
            } else {
                newSelected.push(batch);
            }

            return newSelected
        })
    };

    const handleClickRow = (batch: any) => {

        setDrawerVisible(!drawerVisible)
        setSelectedBatch(batch)
    };

    const handleSelectAll = (rows: any[]) => {
        setSelected(prevSelected => {
            if (prevSelected.length === rows.length) {
                // If all rows are already selected, deselect all
                setBatchedQuantity(0)
                return [];
            } else {
                // Otherwise, select all rows
                const totalQuantity = rows.reduce((accumulator: number, item) => accumulator + item.quantity, 0)
                setBatchedQuantity(totalQuantity)
                return rows.map(row => row.id);
            }
        })

        setSelectedBatches(prevSelected => {
            return prevSelected.length === rows.length ? [] : rows;
        })
    };

    useEffect(() => {
        setShowActionButtons(selected && selected.length > 0)
    }, [selected])

    useEffect(() => {
        fetchBatches(page, pagination.pageSize)
    }, [page, searchTerm])

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inventory', url: undefined },
            { label: 'Batches', url: undefined }
        ])
    }, [])

    const fetchBatches = (page: number, pageSize: number) => {

        dispatch({
            type: INVENTORY_BATCHES_CONSTANTS.LOADING_BATCHES,
            payload: true
        })

        const url = remoteRoutes.inventoryService + '/batches'
        const params = {
            hubId: currentUser?.hubId, page, pageSize, currentStatus: 1
        }

        search(url, params,
            (response: IApiResponse) => {

                const { data, paginationMetadata } = response;
                setPagination(paginationMetadata);

                dispatch({
                    type: INVENTORY_BATCHES_CONSTANTS.FETCH_BATCHES,
                    payload: [...data]
                })

                // setLoading(false)
                toast.success(toastMessages.default.success, overrideToastDefaults)
            },
            (error) => {
                toast.error(toastMessages.default.fail, overrideToastDefaults)
            },
            () => {

            })
    }

    const handleSearch = (event: any) => {
        if (event.target.value) {

        }
    }

    const formattedDate = data.map((row: { date: string }) => ({
        ...row,
        date: printNaturalDateShort(row.date)
    }))

    return (
        <>
            {loading && <>
                <PageHeaderLoader />
                <TableWrapper>
                    <TableFilterLoader />
                    <TableLoader />
                </TableWrapper>
            </>}
            {!loading && <>
                <PageHeader actionButtons={actionButtons} breadcrumbs={breadcrumbs}>

                    <InnerMenu module={Modules.Inventory} />


                </PageHeader>
                <TableWrapper>

                    <div className="d-flex w-100 align-items-center justify-content-between">
                        <div className="input-group w-25">
                            <input type="text" onKeyUp={(event) => handleSearch(event)}
                                className="form-control"
                                placeholder="Type to search..." />
                            <span className="input-group-text" id="addon-wrapping">
                                <FontAwesomeIcon icon={faSearch} />
                            </span>
                        </div>

                        <div className="action-buttons">

                            {showActionButtons &&
                                actionButtons.map((button, index) => {
                                    return (<button
                                        data-bs-toggle={button.dataToggle}
                                        data-bs-target={button.dataTarget}
                                        className={`${button.cssClass} ${button.processing ? 'disabled' : ''}`}
                                        key={index}
                                        onClick={button.onClick}>
                                        {button.processing ? 'Please wait...' : button.label}
                                    </button>)
                                })
                            }
                            {/*<button type="button" className="btn btn-sm btn-outline-secondary ms-2">*/}
                            {/*    <FontAwesomeIcon icon={faFilter} className="text-orange"/>*/}
                            {/*    <span className="ms-2">Filters</span>*/}
                            {/*</button>*/}
                            {/*<button type="button" className="btn btn-sm btn-outline-secondary ms-2">*/}
                            {/*    <FontAwesomeIcon icon={faEllipsisVertical} className="text-orange"/>*/}
                            {/*</button>*/}
                        </div>
                    </div>

                    <XTable
                        selected={selected}
                        onSelectAll={(rows) => handleSelectAll(rows)}
                        onRowClick={(row) => handleClickRow(row)}
                        onCheckRow={(row) => handleCheckRow(row)}
                        data={formattedDate}
                        columns={columns}
                        loading={loading} />

                    <XPagination
                        currentPage={page}
                        pagination={pagination}
                        dataLength={data.length}
                        setPage={(page) => setPage(page)} />

                </TableWrapper>


                <CombineBatchesForm batches={selectedBatches} />
                <MoveBatchForm batch={selectedBatch} />
                <DownloadCsvForm batches={selected} />
                <SellBatchSymosForm batches={selected} />
                <BatchDetails close={() => setDrawerVisible(false)} show={drawerVisible} batch={selectedBatch} />

            </>}
        </>
    )
}