import React, { useEffect, useState } from "react";
import { InnerMenu } from "../../components/shared/InnerMenu";
import { PageHeaderLoader } from "../../components/shared/loaders/PageHeaderLoader";
import { PageHeader } from "../../components/shared/PageHeader";
import TableWrapper from "../../components/shared/TableWrapper";
import XPagination from "../../components/shared/XPagination";
import XTable, { ITableColumn } from "../../components/shared/XTable";
import { Modules } from "../../interfaces/Enums";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { remoteRoutes } from "../../../data/constants";
import { IInputsDistributionState, INPUTS_DISTRIBUTION_CONSTANTS } from "../../../data/redux/inputs/inputsReducer";
import { toastMessages, overrideToastDefaults } from "../../../data/toastDefaults";
import { IState } from "../../../data/types";
import { IPaginationMetadata } from "../../interfaces/IApiResponse";
import { IInputDistribution } from "./interfaces/IInputDistribution";
import { get } from "../../../utils/ajax";
import { buildUrl } from "../../../utils/queryBuilder";
import { useAuth } from "../auth";
import InputStockDetails from "./InputStockDetails";
import { IBreadcrumb } from "../../components/shared/Breadcrumbs";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const columns: ITableColumn[] = [
    { label: "Collection Center", id: "collectionCentreName", textAlign: "text-start" },
    { label: "Input Name", id: "inputName", textAlign: "text-start" },
    { label: "Quantity", id: "quantity", textAlign: "text-start" },
    { label: "Unit", id: "unit", textAlign: "text-start" }
];

const InputStockLedger = () => {

    document.title = "Inputs > Stock"

    const dispatch = useDispatch();
    const { currentUser } = useAuth();
    const [selectedRow, setSelectedRow] = useState<IInputDistribution | null>(null);
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
    const [selected, setSelected] = useState<any[]>([]);
    const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([])

    const [page, setPage] = useState<number>(1);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [pagination, setPagination] = useState<IPaginationMetadata>({
        pageSize: 15,
        hasNextPage: false,
        currentPage: 1,
        hasPreviousPage: false,
        totalItems: 0,
        totalPages: 0
    });

    const { data, loading }: IInputsDistributionState = useSelector((state: IState) => state.inputs);

    const setLoading = (state: boolean) => {
        dispatch({
            type: INPUTS_DISTRIBUTION_CONSTANTS.LOADING_DATA,
            payload: state
        });
    };

    useEffect(() => {
        setLoading(true);
        handleFetchData(page, pagination.pageSize);
    }, [page, searchTerm]);

    const handleFetchData = (page: number, pageSize: number) => {
        if (remoteRoutes.profiles) {
            const HubId = currentUser?.hubId;
            let params: any = { HubId, page, pageSize };

            if (searchTerm) {
                params.searchTerm = searchTerm;
            }

            const url = buildUrl(remoteRoutes.inputsService, '/ledger/centre', params);

            get(
                url,
                (response: any) => {

                    if (!response || !Array.isArray(response)) {
                        return;
                    }

                    const flattenedData = response
                        .flatMap((centre: any) =>
                            centre.inputs?.map((input: any) => ({
                                collectionCentreId: centre.collectionCentreId,
                                collectionCentreName: centre.collectionCentreName,
                                inputId: input.inputId,
                                inputName: input.inputName,
                                quantity: input.quantity,
                                unit: input.unit
                            })) || []
                        )
                        .sort((a, b) => a.collectionCentreName.localeCompare(b.collectionCentreName));

                    dispatch({
                        type: INPUTS_DISTRIBUTION_CONSTANTS.FETCH_DATA,
                        payload: flattenedData
                    });

                    toast.success(toastMessages.default.success, overrideToastDefaults);
                },
                (error) => {
                    toast.error(toastMessages.default.fail, overrideToastDefaults);
                },
                () => {
                    dispatch({ type: INPUTS_DISTRIBUTION_CONSTANTS.STOP_FETCH });
                    setLoading(false);
                }
            );
        }
    };

    const handleSearch = (event: any) => {
        if (event.target.value === '') {
            setSearchTerm(undefined);
        }

        if (event.keyCode === 13) {
            setSearchTerm(event.target.value);
        }
        setPage(1);
    };

    const handleRowClick = (item: any) => {
        setDrawerVisible(true)
        setSelectedRow(item)
    }

    const handleCheckRow = (item: any) => {
        setSelected([item]);
    };

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inputs', url: undefined },
            { label: 'Stock', url: undefined }
        ])
    }, [])

    return (
        <>
            {loading && <PageHeaderLoader />}
            {!loading &&
                <>
                    <PageHeader breadcrumbs={breadcrumbs}>
                        <InnerMenu module={Modules.Inputs} />
                    </PageHeader>
                </>
            }

            <TableWrapper>
                <div className="d-flex w-100 align-items-center justify-content-between">
                    <div className="input-group w-25">
                        <input type="text" onKeyUp={(event) => handleSearch(event)}
                            className="form-control"
                            placeholder="Type to search..." />
                        <span className="input-group-text" id="addon-wrapping">
                            <FontAwesomeIcon icon={faSearch} />
                        </span>
                    </div>
                </div>
                <XTable
                    checked={false}
                    onRowClick={(item) => handleRowClick(item)}
                    onCheckRow={(item) => handleCheckRow(item)}
                    data={data.length > 0 ? data : []}
                    loading={loading}
                    columns={columns}
                />
            </TableWrapper>

            <InputStockDetails close={() => setDrawerVisible(false)} show={drawerVisible} selectedRow={selectedRow} />
        </>
    )
}

export default InputStockLedger

